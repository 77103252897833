import type { StepDataFragment } from '@haaretz/s-fragments/StepData';
import type { PurchasePageSteps } from '@haaretz/s-types';

interface CurrentStep {
  currentStep: StepDataFragment;
  currentStepIndex: number;
}

export default function getCurrentStep(
  currentStepName: PurchasePageSteps,
  steps: StepDataFragment[]
): CurrentStep {
  if (!currentStepName || typeof currentStepName !== 'string') {
    throw new Error("Step name isn't valid");
  }

  if (!steps?.length) {
    throw new Error('Steps is required array');
  }

  const currentStepIndex = steps.findIndex(step => step.name === currentStepName);

  if (currentStepIndex < 0) {
    throw new Error('Invalid current step');
  }
  return {
    currentStep: steps[currentStepIndex],
    currentStepIndex,
  };
}
