'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import HdcLogo from '@haaretz/s-hdc-logo';
import HtzLink from '@haaretz/s-htz-link';
import HtzLogo from '@haaretz/s-htz-logo';
import Icon from '@haaretz/s-icon';
import LayoutContainer from '@haaretz/s-layout-container';
import MarkerLogo from '@haaretz/s-marker-logo';
import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import getCurrentStep from '@haaretz/s-pp-routing-utils/getCurrentStep';
import getCurrentStepName from '@haaretz/s-pp-routing-utils/getCurrentStepName';
import isAbandonedCartRoute from '@haaretz/s-pp-routing-utils/isAbandonedCartRoute';
import isGqlPpStep from '@haaretz/s-pp-routing-utils/isGqlPpStep';
import isThankYouStep from '@haaretz/s-pp-routing-utils/isThankYouStep';
import useBi from '@haaretz/s-use-bi';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import { useRouter } from 'next/router';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';

const promotionPageContentWidth = 368;
// `c` is short for `classNames`
const c = s9.create({
  lc: {
    ...merge(
      mq({
        from: 'xxl',
        value: {
          '--contentWidth': `minmax(0, ${space(promotionPageContentWidth)})`,
        },
      })
    ),
  },
  basePadding: {
    ...merge(
      mq({
        from: 'xl',
        value: {
          paddingTop: fork({ default: space(4), hdc: space(3) }),
          paddingBottom: fork({ default: space(4), hdc: space(3) }),
        },
      })
    ),
  },
  base: {
    alignItems: 'center',
    display: 'grid',
    gridColumnStart: 'main',
    gridTemplateColumns: '1fr auto 1fr',
    paddingBottom: space(2),
    paddingTop: space(2),
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
  backButton: {
    justifySelf: 'start',
    // 1px so that the icon isn't cropped by
    // the negative margin on the icon
    paddingInlineStart: '1px',
    ...merge(
      mq({
        until: 'm',
        value: {
          ...typesetter(-1),
          // 4px so that the icon isn't cropped by
          // the negative margin on the icon
          paddingInlineStart: space(1),
        },
      })
    ),
  },
  logoContainer: {
    textAlign: 'center',
  },
  logo: {
    fontSize: space(7),
    ...merge(
      mq({
        from: 'xl',
        value: {
          fontSize: space(9),
        },
      })
    ),
  },
  tmLogo: {
    color: color('primary800'),
  },
  hdcLogo: {
    fontSize: space(8),
    color: color('neutral1200'),
    ...merge(
      mq({
        from: 'm',
        until: 'xl',
        value: {
          fontSize: space(9),
        },
      }),
      mq({
        from: 'xl',
        value: {
          fontSize: space(11),
        },
      })
    ),
  },
  safePurchaseWidth: {
    paddingTop: fork({
      default: space(1),
      hdc: 'initial',
    }),
    width: space(14),
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(17),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: fork({
            default: space(17),
            hdc: space(17.5),
          }),
        },
      })
    ),
  },

  backButtonIcon: {
    transform: fork({
      default: 'initial',
      hdc: 'rotate(180deg)',
    }),
  },
  safePurchase: {
    alignItems: 'center',
    aspectRatio: '1',
    borderRadius: radius('circle'),
    backgroundColor: color('ppSafePurchaseBg'),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    justifySelf: 'end',
    textAlign: 'center',
    ...merge({
      ...border({
        width: '2px',
        style: 'solid',
        spacing: 1,
        side: 'all',
        color: color('quaternary700', { opacity: 0.3 }),
      }),
    }),
  },

  lockIcon: {
    fontSize: space(6),
    ...merge(
      mq({
        from: 'xl',
        value: {
          fontSize: space(8),
        },
      })
    ),
  },
  safePurchaseText: {
    fontWeight: fork({
      default: 500,
      hdc: 400,
    }),
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    color: color('quaternary700'),
    ...typesetter(-4, { lines: 3 }),
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-4, { lines: 3 }),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...fork({
            default: { ...typesetter(-5, { lines: 3 }) },
            hdc: { ...typesetter(-5, { lines: 3 }) },
          }),
        },
      })
    ),
  },
});

const cleanReload = () => {
  const reloadLocation =
    window.location.origin ||
    `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;

  window.location.assign(reloadLocation);
};

export default function PpMasthead() {
  const router = useRouter();
  const currentStepName = getCurrentStepName(router.pathname);
  const biAction = useBi();
  const variables = usePpDataVariables();
  const { data } = usePurchasePagePersonalQuery(variables, { enabled: false });

  const pageData = data?.PurchasePage?.pageData;
  const buttonShouldReload = isAbandonedCartRoute(router.query);

  const getBiData = () => {
    const currentStep =
      pageData &&
      isGqlPpStep(currentStepName) &&
      getCurrentStep(currentStepName, pageData.allSteps).currentStepIndex + 1;

    const additionalInfoBiData: BiDataOverrides = {
      additional_info: {
        ...(currentStep ? { stage_number: currentStep.toString() } : {}),
      },
    };

    const biData = {
      stage: currentStepName,
      ...additionalInfoBiData,
    };

    return biData;
  };

  const backButtonClick = () => {
    biAction({
      action_id: 169,
      feature: 'Back on promotions',
      feature_type: 'Marketing',
      ...getBiData(),
    });

    if (buttonShouldReload) cleanReload();
    else router.back();
  };

  const logoOnClick = () => {
    biAction({
      action_id: 80,
      feature: 'Promotions footer',
      feature_type: 'Content',
      ...getBiData(),
    });
  };

  const hideBackButton = isThankYouStep(currentStepName);

  return (
    <LayoutContainer as="header" styleExtend={[c.lc]}>
      <div className={s9(c.base, c.basePadding)}>
        <Button
          onClick={backButtonClick}
          variant="neutral"
          priority="tertiary"
          startIcon="chevron"
          styleExtend={[c.backButton, hideBackButton && c.hidden]}
          startIconStyleExtend={[c.backButtonIcon]}
          data-testid="pp-masthead-back-button"
        >
          {fork({
            default: 'חזרה',
            hdc: 'Back',
          })}
        </Button>
        <div className={s9(c.logoContainer)}>
          {fork({
            default: (
              <HtzLink
                href="https://www.haaretz.co.il"
                onClick={logoOnClick}
                data-testid="pp-masthead-logo-link"
              >
                <HtzLogo styleExtend={[c.logo]} />
              </HtzLink>
            ),
            tm: (
              <HtzLink
                href="https://www.themarker.com"
                onClick={logoOnClick}
                data-testid="pp-masthead-logo-link"
              >
                <MarkerLogo styleExtend={[c.logo, c.tmLogo]} />
              </HtzLink>
            ),
            hdc: (
              <HtzLink
                href="https://www.haaretz.com"
                onClick={logoOnClick}
                data-testid="pp-masthead-logo-link"
              >
                <HdcLogo styleExtend={[c.hdcLogo]} />
              </HtzLink>
            ),
          })}
        </div>
        <div className={s9(c.safePurchase, c.safePurchaseWidth)}>
          <Icon icon="lock" variant="success" styleExtend={[c.lockIcon]} />
          <span className={s9(c.safePurchaseText)}>
            {fork({ default: 'תשלום מאובטח', hdc: 'Secure Payment' })}
          </span>
        </div>
      </div>
    </LayoutContainer>
  );
}
