'use client';

import color from '@haaretz/l-color.macro';
import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useUser from '@haaretz/s-atoms/user';
import usePpLogout from '@haaretz/s-auth-hooks/use-pp-logout';
import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import generatePath from '@haaretz/s-pp-routing-utils/generatePath';
import getCurrentStep from '@haaretz/s-pp-routing-utils/getCurrentStep';
import getCurrentStepName from '@haaretz/s-pp-routing-utils/getCurrentStepName';
import isGqlPpStep from '@haaretz/s-pp-routing-utils/isGqlPpStep';
import TextLink from '@haaretz/s-text-link';
import useBi from '@haaretz/s-use-bi';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import { QueryObserver, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import * as React from 'react';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

const c = s9.create({
  base: {
    backgroundColor: color('neutral300'),
    textAlign: 'center',
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    ...merge(
      { ...typesetter(0) },
      mq({
        until: 'xl',
        value: fork({ default: { marginBottom: space(4) }, hdc: { marginBottom: space(3) } }),
      }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-1) } }),
      mq({
        from: 'xxl',
        value: fork({
          default: { ...typesetter(-2) },
          hdc: { ...typesetter(-1) },
        }),
      })
    ),
    paddingTop: space(2),
    paddingBottom: space(2),
  },
  specialDataBg: {
    backgroundColor: color('neutral200', { opacity: 0.2 }),
  },
});

export interface ChangeAccountProps {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

export default function ChangeAccountBanner({ inlineStyle, styleExtend = [] }: ChangeAccountProps) {
  const router = useRouter();
  const client = useQueryClient();
  const user = useUser('cookieValue');

  const currentStepName = React.useMemo(
    () => getCurrentStepName(router.pathname),
    [router.pathname]
  );
  const biAction = useBi();

  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const variables = usePpDataVariables();

  const { data: promotionsData } = usePurchasePagePersonalQuery(variables, {
    enabled: false,
  });

  const isSpecialOffers =
    !!promotionsData?.PurchasePage?.pageData?.specialSaleData && currentStepName === 'offers';

  const logoutData = usePpLogout({
    onSuccess: data => {
      if (data.logout.success) {
        setShouldRedirect(true);
      }
    },
  });

  React.useEffect(() => {
    const enabled =
      shouldRedirect &&
      !variables.couponId &&
      !variables.previousSteps &&
      !variables.selectedOfferId;
    const observer = new QueryObserver(client, {
      enabled,
      queryKey: usePurchasePagePersonalQuery.getKey(variables),
      queryFn: usePurchasePagePersonalQuery.fetcher(variables),
    });

    const unsubscribe = observer.subscribe(({ status, data: ppData }) => {
      if (enabled && status === 'success') {
        const steps = ppData.PurchasePage?.pageData.steps || [];

        const nextStep = steps[0];

        const { pathName, asPath } = generatePath({
          nextStepName: nextStep?.name || 'offers',
          asPath: router.asPath,
          currentQueryParams: router.query,
          ignoreCurrentSearchParams: true,
        });

        router.replace(pathName, asPath, { shallow: true });
        setShouldRedirect(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [router, client, variables, currentStepName, biAction, shouldRedirect]);

  function logoutOnClick(evt: React.MouseEvent<HTMLAnchorElement>) {
    evt.preventDefault();

    const getBiData = () => {
      const pageData = promotionsData?.PurchasePage?.pageData;

      const currentStep =
        pageData &&
        isGqlPpStep(currentStepName) &&
        getCurrentStep(currentStepName, pageData.allSteps).currentStepIndex + 1;

      const additionalInfoBiData: BiDataOverrides = {
        additional_info: {
          ...(currentStep ? { stage_number: currentStep.toString() } : {}),
        },
      };

      const biData = {
        stage: currentStepName,
        ...additionalInfoBiData,
      };

      return biData;
    };

    biAction({ ...getBiData() });

    logoutData.mutate({});
  }

  if (!user.firstName) {
    return null;
  }

  return (
    <div
      className={s9(c.base, isSpecialOffers && c.specialDataBg, ...styleExtend)}
      style={inlineStyle}
    >
      {fork({ default: 'שלום', hdc: 'hello' })} {user.firstName},{' '}
      <TextLink href="/offers" onClick={logoutOnClick}>
        {fork({ default: 'להחלפת משתמש', hdc: 'change user' })}
      </TextLink>
    </div>
  );
}
