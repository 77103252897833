import createApp from '@haaretz/s-next-create-app';
import openSans, { OPEN_SANS_VAR } from '@haaretz/s-next-font-open-sans';
import PpPageLayout from '@haaretz/s-pp-page-layout';
import config from 'config';

const PromotionPageHtzApp = createApp({
  config,
  Layout: PpPageLayout,
  fonts: [[openSans, OPEN_SANS_VAR]],
});

export default PromotionPageHtzApp;
