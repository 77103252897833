import type { PurchasePageSteps, NonGqlPpSteps } from '@haaretz/s-types';

type PpSteps = PurchasePageSteps | NonGqlPpSteps;

export default function getCurrentStepName(pathname: string): PpSteps {
  /* istanbul ignore else */
  if (!pathname.startsWith('/')) return pathname as PpSteps;

  return pathname.slice(1) as PpSteps;
}
