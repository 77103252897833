/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import { hostname } from '@haaretz/s-consts';
import PPChangeAccountBanner from '@haaretz/s-pp-change-account-banner';
import PPFooter from '@haaretz/s-pp-footer';
import PPMasthead from '@haaretz/s-pp-masthead';
import { BiDataContextProvider } from '@haaretz/s-use-bi';
import PPDataStoreContextProvider from '@haaretz/s-use-pp-data-store';
import PPDataStepsContextProvider from '@haaretz/s-use-pp-offset-steps-store';
import Head from 'next/head';
import React from 'react';
import s9 from 'style9';

import PpPageSaver from './components/PpPageSaver';
import PpPageviewSender from './components/PpPageviewSender';
import PpSideEffects from './components/PpSideEffects';

const c = s9.create({
  wrapper: {
    minHeight: '100vh',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
  },
  layout: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'max-content',
  },
});

const title = fork({
  default: 'מינוי לאתר הארץ',
  tm: 'מינוי לאתר TheMarker – מנוי לדה מרקר',
  hdc: 'Haaretz Digital Subscriptions',
});

const description = fork({
  default:
    'רכשו מינוי לאתר הארץ וקבלו את כל תכני הארץ במחשב ובאפליקציה, פחות פרסומות, פודקאסט שבועי ועדכונים שוטפים לכל מכשיר שתרצו.',
  tm: 'מינוי לאתר TheMarker: רכשו מנוי לדה מרקר וקבלו את כל תכני דהמרקר במחשב ובאפליקציה , פחות פרסומות, פודקאסט שבועי ועדכונים שוטפים לכל מכשיר שתרצו.',
  hdc: "Haaretz digital subscriptions: Get the most reliable reporting on Israel, the Middle East and the Jewish world. Subscribe now to Israel's leading newspaper.",
});

export default function PurchasePageLayout({ children }: React.PropsWithChildren<unknown>) {
  return (
    <>
      <PpSideEffects />
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={`https://promotion.${hostname}/`} />
      </Head>
      <div className={s9(c.wrapper)}>
        <PPDataStoreContextProvider>
          <PPDataStepsContextProvider>
            <PpPageSaver />
            <BiDataContextProvider>
              <PpPageviewSender />
              <PPMasthead />
              <main className={s9(c.layout)}>
                <PPChangeAccountBanner />
                {children}
              </main>
              <PPFooter />
            </BiDataContextProvider>
          </PPDataStepsContextProvider>
        </PPDataStoreContextProvider>
      </div>
    </>
  );
}
