import config from '@haaretz/l-config';
import useOnce from '@haaretz/s-use-once';

const personalizationServise = config.get('personalization');

function logPurchaseVisit() {
  fetch(`${personalizationServise}/marketing/log-purchase-visit`, {
    referrerPolicy: 'unsafe-url',
    credentials: 'include',
  }).catch(() => null);
}

export default function useLogPurchaseVisit() {
  useOnce(logPurchaseVisit, true);
}
