'use client';

import { PP_FLOWS } from '@haaretz/s-consts';
import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import getCurrentStep from '@haaretz/s-pp-routing-utils/getCurrentStep';
import getCurrentStepName from '@haaretz/s-pp-routing-utils/getCurrentStepName';
import isGqlPpStep from '@haaretz/s-pp-routing-utils/isGqlPpStep';
import isThankYouStep from '@haaretz/s-pp-routing-utils/isThankYouStep';
import useBi from '@haaretz/s-use-bi';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import { usePPPrevStepsStoreActions } from '@haaretz/s-use-pp-offset-steps-store';
import { useRouter } from 'next/router';
import React from 'react';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type { NonGqlPpSteps } from '@haaretz/s-types';
import type { ppQueryVariables } from '@haaretz/s-use-pp-data-variables';

type DynamicInitialSteps = Partial<Record<Extract<PurchasePageSteps, 'login'>, true>>;

const initialSteps: Partial<Record<PurchasePageSteps | NonGqlPpSteps, true>> = {
  offers: true,
  coupon: true,
  updateDetails: true,
};

export default function PpPageviewSender() {
  const biRequest = useBi('pageview');
  const router = useRouter();

  const dynamicInitialSteps = React.useMemo<DynamicInitialSteps>(
    () => ({
      login: !!(router.query.flow === PP_FLOWS.newsletter && router.query.email) || undefined,
    }),
    [router.query]
  );

  const prevPathnameRef = React.useRef<string | null>(null);
  const currentStepName = getCurrentStepName(router.pathname);
  const { getPrevSteps } = usePPPrevStepsStoreActions();

  const excludedVariables: (keyof Required<ppQueryVariables>)[] = React.useMemo(
    () => ({ ...initialSteps, ...dynamicInitialSteps })[currentStepName] ? ['selectedOfferId'] : [],
    [currentStepName, dynamicInitialSteps]
  );

  const variables = usePpDataVariables(excludedVariables);
  const { data, isFetching, isLoading, isFetched } = usePurchasePagePersonalQuery(variables, {
    enabled: false,
  });

  React.useEffect(() => {
    const getBiData = () => {
      const allSteps =
        data?.PurchasePage?.pageData?.allSteps ??
        getPrevSteps().map(name => ({ __typename: 'StepData', name }));

      const currentStep =
        allSteps.length &&
        isGqlPpStep(currentStepName) &&
        getCurrentStep(currentStepName, allSteps).currentStepIndex + 1;

      const additionalInfoBiData: BiDataOverrides = {
        additional_info: {
          ...(currentStep ? { stage_number: currentStep.toString() } : {}),
        },
      };

      const biData = {
        stage: currentStepName,
        ...additionalInfoBiData,
      };

      return biData;
    };

    if (
      prevPathnameRef.current !== router.pathname &&
      ((!isFetching && !isLoading && isFetched) ||
        isThankYouStep(currentStepName, ['thankYou', 'changeSuccess']))
    ) {
      prevPathnameRef.current = router.pathname;

      biRequest({
        ...getBiData(),
      });
    }
  }, [
    biRequest,
    currentStepName,
    data?.PurchasePage?.pageData?.allSteps,
    getPrevSteps,
    isFetched,
    isFetching,
    isLoading,
    router.pathname,
    variables,
  ]);

  return null;
}
