import type { PurchasePageSteps, NonGqlPpSteps } from '@haaretz/s-types';

type PpSteps = PurchasePageSteps | NonGqlPpSteps;

export const validGqlPpSteps: Record<PurchasePageSteps, true> & Record<NonGqlPpSteps, false> = {
  // GQL steps
  debt: true,
  login: true,
  offers: true,
  payment: true,
  specialMax: true,
  specialSubscription: true,
  thankYou: true,
  updateDetails: true,
  validSubscription: true,

  // Non GQL steps
  coupon: false,
  error: false,
  changeSuccess: false,
  paymentChange: false,
  quickPlanChange: false,
};

export default function isGqlPpStep(step: PpSteps): step is PurchasePageSteps {
  return validGqlPpSteps[step] || false;
}
