import browserStorageGetItem from '@haaretz/s-browser-storage-utils/getItem';
import browserStorageSetItem from '@haaretz/s-browser-storage-utils/setItem';
import { USER_ASSISTANCE } from '@haaretz/s-consts';

import type { UserAssistance } from '@haaretz/s-types';

const initialUserAssistance: UserAssistance = {
  entryDate: null,
  bannerDisplayed: false,
  count: 0,
  lastUpdateDate: null,
};

export default function initAssistance() {
  const userAssistance = browserStorageGetItem({ key: USER_ASSISTANCE, storage: 'local' });

  /* istanbul ignore if */
  if (!userAssistance)
    browserStorageSetItem({ key: USER_ASSISTANCE, storage: 'local', value: initialUserAssistance });

  const shouldResetAssistance = getShouldResetAssitanceDetails();

  /* istanbul ignore if */
  if (shouldResetAssistance) {
    resetAssitanceDetails();
  }

  addAssitanceVisit();
}

function addAssitanceVisit() {
  const userAssistance = browserStorageGetItem({ key: USER_ASSISTANCE, storage: 'local' });

  /* istanbul ignore if */
  if (!userAssistance) return;

  const {
    count,
    entryDate: prevEntryDate,
    bannerDisplayed,
  } = JSON.parse(userAssistance) as UserAssistance;

  if (bannerDisplayed) return;

  const newEntryDate = new Date().toISOString();

  const updatedUserAssistance: UserAssistance = {
    bannerDisplayed,
    lastUpdateDate: prevEntryDate,
    count: count + 1,
    entryDate: newEntryDate,
  };

  browserStorageSetItem({ key: USER_ASSISTANCE, value: updatedUserAssistance, storage: 'local' });
}

function getShouldResetAssitanceDetails() {
  const userAssistance = browserStorageGetItem({ key: USER_ASSISTANCE, storage: 'local' });

  /* istanbul ignore if */
  if (!userAssistance) return false;

  const { lastUpdateDate } = JSON.parse(userAssistance) as UserAssistance;

  if (!lastUpdateDate) return false;

  const currentMonth = new Date().getMonth();
  const lastUpdateDateMonth = new Date(lastUpdateDate).getMonth();
  const currentYear = new Date().getFullYear();
  const lastUpdateDateYear = new Date(lastUpdateDate).getFullYear();

  /* istanbul ignore if */
  if (currentMonth !== lastUpdateDateMonth || currentYear !== lastUpdateDateYear) {
    return true;
  }

  return false;
}

export function resetAssitanceDetails() {
  browserStorageSetItem({ key: USER_ASSISTANCE, storage: 'local', value: initialUserAssistance });
}
