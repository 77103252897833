import fork from '@haaretz/l-fork.macro';
import initAssistance from '@haaretz/s-pp-assistance-utils/initAssitance';
import { useRouter } from 'next/router';
import React from 'react';

export default function useUserAssistance() {
  const router = useRouter();
  const hasRenderedRef = React.useRef(false);

  // we use useLayoutEffect because we want it to fire before it's children useEffect
  React.useLayoutEffect(() => {
    const shouldInitAssistance = fork({ default: router.pathname === '/offers', tm: false });

    if (!hasRenderedRef.current && shouldInitAssistance) {
      hasRenderedRef.current = true;
      initAssistance();
    }
  }, [router.pathname]);
}
