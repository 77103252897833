import { usePPDataStoreActions, usePPUpdateCouponStore } from '@haaretz/s-use-pp-data-store';
import { usePPOffsetStepsStoreActions } from '@haaretz/s-use-pp-offset-steps-store';
import { useRouter } from 'next/router';

import useLogout from '../use-logout';

import type { LogoutMutation, LogoutMutationVariables } from '@haaretz/s-lgn-mutations/Logout';
import type { UseMutationOptions } from '@tanstack/react-query';

export default function usePpLogout<TError = Error, TContext = unknown>(
  options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>
) {
  const { clearStore } = usePPDataStoreActions();
  const updateCoupon = usePPUpdateCouponStore();
  const { clearSteps } = usePPOffsetStepsStoreActions();
  const router = useRouter();

  const logout = useLogout({
    ...options,

    onSuccess: async (data, variables, context) => {
      // istanbul ignore else
      if (data.logout.success) {
        router.replace({ ...router, query: { pathSegment: router.query.pathSegment } }, undefined, {
          shallow: true,
        });
        clearStore();
        clearSteps();
        updateCoupon(null);
      }

      // istanbul ignore else
      if (typeof options?.onSuccess === 'function') {
        options.onSuccess(data, variables, context);
      }
    },
  });

  return logout;
}
