import { URLSearchParams } from 'url';

import type { PurchasePageSteps, NonGqlPpSteps } from '@haaretz/s-types';

type PpSteps = PurchasePageSteps | NonGqlPpSteps;

type QueryType = Record<string, string | string[] | boolean | undefined>;

interface PathGeneratorProps {
  asPath: string;
  currentQueryParams: QueryType;
  nextStepName: PpSteps;
  ignoreCurrentSearchParams?: boolean;
  extendQueryParams?: QueryType;
  searchParams?: URLSearchParams;
}

export default function generatePath({
  nextStepName,
  asPath,
  searchParams,
  currentQueryParams,
  ignoreCurrentSearchParams = false,
  extendQueryParams = {},
}: PathGeneratorProps) {
  const [, queryPartFromPath] = asPath.split(/\?(.+)/);

  const extendSearchParams = searchParams?.toString();

  const segmentPath = currentQueryParams.pathSegment ? `/${currentQueryParams.pathSegment}` : '';

  const computedAsPath = `${segmentPath}/${nextStepName}${
    queryPartFromPath && !ignoreCurrentSearchParams
      ? `?${queryPartFromPath}${extendSearchParams ? `&${extendSearchParams}` : ''}`
      : extendSearchParams
      ? `?${extendSearchParams}`
      : ''
  }`;

  const pathObj = {
    asPath: computedAsPath,
    pathName: {
      pathname: `${segmentPath}/${nextStepName}`,
      query: { ...currentQueryParams, ...extendQueryParams },
    },
  };
  return pathObj;
}
