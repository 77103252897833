'use client';

import NoSSR from '@haaretz/s-no-ssr';

import useLogPurchaseVisit from '../../hooks/useLogVisit';
import useUserAssistance from '../../hooks/useUserAssistance';

function PpSideEffects() {
  useLogPurchaseVisit();

  return null;
}

export default function PpSideEffectsWrapper() {
  useUserAssistance();

  return (
    <NoSSR>
      <PpSideEffects />
    </NoSSR>
  );
}
