import AtomProviders from '@haaretz/s-next-layout/AtomProviders';
import { ConfigContext } from '@haaretz/s-use-config';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import s9 from 'style9';

import type { AppGlobalType } from '@haaretz/s-data-structure-types';
import type { PlatformType, FontVariable, HtzUser } from '@haaretz/s-types';
import type { IConfig } from 'config';
import type { AppProps } from 'next/app';
import type { NextFontWithVariable } from 'next/dist/compiled/@next/font/dist/types';

const c = s9.create({
  reactQueryDevtools: {
    direction: 'ltr',
  },
});

interface HtzAppProps {
  dehydratedState: Record<string, string>;
  user: HtzUser;
  userAgent: string;
  cookie: string;
  platform: PlatformType;
}

type FontSettings = [NextFontWithVariable, FontVariable];

interface Config {
  config: IConfig;
}

interface CreateAppProps extends Config {
  fonts?: FontSettings[];
  Layout?: React.ComponentType<React.PropsWithChildren<unknown>>;
}

let queryClient = queryClientFactory();

function queryClientFactory() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        keepPreviousData: true,
        retry: false,
        staleTime: 600000,
      },
    },
  });
}

export default function createApp({ config, fonts, Layout = React.Fragment }: CreateAppProps) {
  function HaaretzApp({ Component, pageProps }: AppProps<HtzAppProps>): JSX.Element {
    if (typeof window === 'undefined') {
      queryClient = queryClientFactory();
    }

    const fontVars =
      fonts && fonts.map(font => `${font[1]}:${font[0].style.fontFamily};`).join('\n');
    const globalStyles = (
      /* eslint-disable-next-line react/no-unknown-property*/
      <style jsx global>
        {`
          html {
            ${fontVars}
          }
        `}
      </style>
    );

    const initialGlobalStore: AppGlobalType = {
      animationReduced: null,
      articleId: null,
      articleType: null,
      authors: null,
      contrastMode: null,
      magazineCoverColor: null,
      page: null,
      pageStatusToken: null,
      pageType: 'PurchasePage',
      paywallType: null,
      platform: pageProps.platform || 'mobile',
      previewId: null,
      renderingKind: null,
      tags: null,
      teasersOnPage: null,
      user: pageProps.user,
      zenMode: null,
    };

    return (
      <ConfigContext.Provider value={config}>
        {fontVars ? globalStyles : null}
        <AtomProviders initialGlobalStore={initialGlobalStore}>
          {/* <HydrateAtoms initialValues={initialStore} /> */}
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </Hydrate>
            <ReactQueryDevtools
              initialIsOpen={false}
              panelProps={{ className: s9(c.reactQueryDevtools) }}
              position="bottom-right"
            />
          </QueryClientProvider>
        </AtomProviders>
      </ConfigContext.Provider>
    );
  }

  return HaaretzApp;
}
