import type { PurchasePageSteps, NonGqlPpSteps } from '@haaretz/s-types';

type PpSteps = PurchasePageSteps | NonGqlPpSteps;

type ThankYouPpStep = Extract<
  PpSteps,
  'thankYou' | 'validSubscription' | 'specialSubscription' | 'changeSuccess'
>;
export default function isThankYouStep(
  step: PpSteps,
  thankYouSteps?: ThankYouPpStep[]
): step is ThankYouPpStep {
  if (
    (step === 'thankYou' ||
      step === 'validSubscription' ||
      step === 'specialSubscription' ||
      step === 'changeSuccess') &&
    (!thankYouSteps || thankYouSteps.includes(step))
  ) {
    return true;
  }

  return false;
}
