'use client';

import color from '@haaretz/l-color.macro';
import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import getCurrentStep from '@haaretz/s-pp-routing-utils/getCurrentStep';
import getCurrentStepName from '@haaretz/s-pp-routing-utils/getCurrentStepName';
import isGqlPpStep from '@haaretz/s-pp-routing-utils/isGqlPpStep';
import useBi from '@haaretz/s-use-bi';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import { useRouter } from 'next/router';
import * as React from 'react';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { ppQueryVariables } from '@haaretz/s-use-pp-data-variables';

const c = s9.create({
  base: {
    justifyItems: 'center',
    backgroundColor: color('footerBg'),
    color: color('footerColor'),
    display: 'grid',
    marginTop: 'auto',
    paddingBottom: space(12),
    paddingTop: space(10),
    textAlign: 'center',
    width: '100%',
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(1),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(0),
        },
      })
    ),
  },
  icon: {
    ...typesetter(6),
    ...merge(
      mq({
        from: 'xl',
        value: {
          ...typesetter(7),
        },
      })
    ),

    ...merge(mq({ until: 'xl', value: { marginBottom: space(2) } })),
  },
  homePageLink: {
    fontWeight: fork({
      default: 600,
      hdc: 700,
    }),
    marginTop: space(2),
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    ...merge(
      mq({
        until: 'xl',
        value: {
          display: fork({
            default: 'none',
            hdc: 'block',
          }),
        },
      }),
      mq({
        from: 'xl',
        value: {
          marginTop: fork({
            default: space(2),
            hdc: space(4),
          }),
        },
      })
    ),
  },

  linkWrapper: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'center',
    marginTop: fork<ReturnType<typeof space> | 0>({
      default: space(5),
      hdc: 0,
    }),
    maxWidth: space(140),
    ...merge(
      mq({ until: 'xl', value: { ...typesetter(0, { lines: 7 }) } }),
      mq({
        from: 'xl',
        value: {
          '--links-gap': fork({
            default: space(15),
            hdc: space(8),
          }),
          columnGap: 'var(--links-gap)',
          flexDirection: 'row',
          marginTop: fork({
            default: space(6),
            hdc: space(4),
          }),
        },
      })
    ),
  },

  link: {
    fontWeight: fork({
      default: 650,
      hdc: 400,
    }),
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    ...merge(
      mq({
        until: 'xl',
        value: {
          marginTop: space(1),
        },
      }),
      mq({
        from: 'xl',
        value: {
          fontWeight: 400,
          position: 'relative',
        },
      })
    ),
  },
  linkWithSeparator: {
    ...merge(
      mq({
        from: 'xl',
        value: {
          '::before': {
            content: fork({
              default: '"●"',
              hdc: '"|"',
            }),
            cursor: 'text',
            insetInlineStart: 'calc(var(--links-gap) / 2 * -1)',
            pointerEvents: 'none',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%) translateX(50%)',
            ...typesetter(-2),
          },
        },
      })
    ),
  },
});

export default function PpFooter() {
  const router = useRouter();
  const currentStepName = getCurrentStepName(router.pathname);
  const biAction = useBi();
  const excludedVariables: (keyof Required<ppQueryVariables>)[] = React.useMemo(
    () => ['selectedOfferId'],
    []
  );
  const variables = usePpDataVariables(excludedVariables);
  const { data } = usePurchasePagePersonalQuery(variables, { enabled: false });

  const pageData = data?.PurchasePage?.pageData;

  const getBiData = () => {
    const currentStep =
      pageData &&
      isGqlPpStep(currentStepName) &&
      getCurrentStep(currentStepName, pageData.allSteps).currentStepIndex + 1;

    const additionalInfoBiData: BiDataOverrides = {
      additional_info: {
        ...(currentStep ? { stage_number: currentStep.toString() } : {}),
      },
    };

    const biData: BiDataOverrides = {
      stage: currentStepName,
      feature: 'Promotions footer',
      feature_type: 'Marketing',
      ...additionalInfoBiData,
    };

    return biData;
  };

  const logoOnClick = () => {
    biAction({
      action_id: 80,
      feature: 'Promotions footer',
      feature_type: 'Content',
      ...getBiData(),
    });
  };

  return (
    <div className={s9(c.base)}>
      <HtzLink
        href={fork({
          default: 'https://www.haaretz.co.il',
          tm: 'https://www.themarker.com',
          hdc: 'https://www.haaretz.com',
        })}
        onClick={logoOnClick}
        data-testid="pp-footer-icon-link"
      >
        <Icon icon={fork({ default: 'alef', tm: 'm' })} styleExtend={[c.icon]} />
        <VisuallyHidden>
          {fork({
            default: 'לדף הבית',
            hdc: 'To Home Page',
          })}
        </VisuallyHidden>
      </HtzLink>
      <HtzLink
        href={fork({
          default: 'https://www.haaretz.co.il',
          tm: 'https://www.themarker.com',
          hdc: 'https://www.haaretz.com',
        })}
        onClick={logoOnClick}
        className={s9(c.homePageLink)}
        data-testid="pp-footer-home-page-link"
      >
        {fork({
          default: 'דף הבית',
          hdc: 'HOME PAGE',
        })}
      </HtzLink>
      <div className={s9(c.linkWrapper)}>
        {fork({
          default: (
            <HtzLink
              className={s9(c.link)}
              onClick={() =>
                biAction({
                  action_id: 50,
                  ...getBiData(),
                })
              }
              href={fork({
                default: 'https://www.lndit.co/6016a881af8b9.htm',
                tm: 'https://www.lndit.co/6016998b5ea77.htm',
              })}
              data-testid="pp-footer-organization-subscription-link"
            >
              לרכישת מינוי ארגוני
            </HtzLink>
          ),
          hdc: null,
        })}
        <HtzLink
          className={s9(
            c.link,
            fork({
              default: c.linkWithSeparator,
              hdc: null,
            })
          )}
          onClick={() => {
            biAction({
              action_id: 43,
              ...getBiData(),
            });
          }}
          href={fork({
            default: 'https://www.haaretz.co.il/misc/faq',
            tm: 'https://www.themarker.com/misc/faq',
            hdc: 'https://www.haaretz.com/misc/faq',
          })}
          data-testid="pp-footer-faq-link"
        >
          {fork({
            default: 'שאלות ותשובות',
            hdc: 'FAQ',
          })}
        </HtzLink>
        <HtzLink
          className={s9(c.link, c.linkWithSeparator)}
          onClick={() => {
            biAction({
              action_id: 41,
              ...getBiData(),
            });
          }}
          href={fork({
            default: 'https://www.haaretz.co.il/misc/contact-us',
            tm: 'https://www.themarker.com/misc/contact-us',
            hdc: 'https://www.haaretz.com/contact-us',
          })}
          data-testid="pp-footer-contact-us-link"
        >
          {fork({
            default: 'צרו קשר',
            hdc: 'CONTACT US',
          })}
        </HtzLink>
      </div>
    </div>
  );
}
