'use client';

import getCurrentStepName from '@haaretz/s-pp-routing-utils/getCurrentStepName';
import { usePPPrevStepsStoreActions } from '@haaretz/s-use-pp-offset-steps-store';
import { useRouter } from 'next/router';
import React from 'react';

export default function PpPageSaver() {
  const router = useRouter();
  const { pushPrevStep } = usePPPrevStepsStoreActions();

  React.useEffect(() => {
    const stepName = getCurrentStepName(router.pathname);

    pushPrevStep(stepName);
  }, [pushPrevStep, router.pathname]);

  return null;
}
