import React from 'react';

import type { IConfig } from 'config';

export const ConfigContext = React.createContext<IConfig | undefined>(undefined);

//  Hook  //
////////////

export default function useConfig() {
  const context = React.useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigContext');
  }

  return context;
}
